import React, { useMemo } from "react"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import LangAwareLink from "./LangAwareLink"
import { useLanguageContext } from "../helpers/LanguageContext"

import Style from "./Footer.module.css"
import { Address, Phone } from "./Address";

const linkMaps = {
    de: [{
        id: "navigation",
        title: "Navigation",
        links: [{
            id: "home",
            title: "Startseite",
            to: "/"
        }, {
            id: "about",
            title: "Über uns",
            to: "/about-us"
        }, {
            id: "imprint",
            title: "Impressum",
            to: "/imprint"
        }, {
            id: "privacy",
            title: "Datenschutz",
            to: "/privacy"
        },]
    }, {
        id: "products",
        title: "Produkte",
        links: [{
            id: "training",
            title: "Training",
            to: "/products/training"
        }, {
            id: "sales",
            title: "Sales",
            to: "/products/sales"
        }, {
            id: "culture",
            title: "Culture",
            to: "/products/culture"
        }]
    },],
    en: [{
        id: "navigation",
        title: "Navigation",
        links: [{
            id: "home",
            title: "Start",
            to: "/"
        }, {
            id: "about",
            title: "About Us",
            to: "/about-us"
        }, {
            id: "imprint",
            title: "Imprint",
            to: "/imprint"
        }, {
            id: "privacy",
            title: "Privacy",
            to: "/privacy"
        },]
    }, {
        id: "products",
        title: "Products",
        links: [{
            id: "training",
            title: "Training",
            to: "/products/training"
        }, {
            id: "sales",
            title: "Sales",
            to: "/products/sales"
        }, {
            id: "culture",
            title: "Culture",
            to: "/products/culture"
        }]
    },],
}

const translations = {
    de: {
        address: "Adresse",
    },
    en: {
        address: "Address",
    },
}

const Footer: React.FC = () => {
    const {
        tuevLogo,
    } = useStaticQuery(graphql`
        query FooterQuery {
            tuevLogo: file(relativePath: { eq: "tuev-sued.png" }) {
                childImageSharp {
                    fluid(maxWidth: 96, maxHeight: 96) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    const { currentLangKey } = useLanguageContext()

    const { linkMap } = useMemo(() => {
        const linkMap = linkMaps[currentLangKey]

        return {
            linkMap,
        }
    }, [currentLangKey])

    const trans = translations[currentLangKey]

    return <footer className={Style.footer}>
        <div className={Style.footer_content}>
            <div className="flex flex-wrap desktop:flex-no-wrap">
                <ul className={Style.nav_list}>
                    {linkMap.map(nav => {
                        return <li className={Style.nav_wrapper} key={`footer-nav-${nav.id}`}>
                            <span className={Style.nav_title}>{nav.title}</span>

                            <ul className={Style.nav}>
                                {nav.links.map(link => {
                                    return <li
                                        className={Style.nav_link}
                                        key={`footer-nav-${nav.id}-link-${link.id}`}
                                    >
                                        <LangAwareLink to={link.to}>{link.title}</LangAwareLink>
                                    </li>
                                })}
                            </ul>
                        </li>
                    })}
                </ul>
                <div className="desktop:w-1/4">
                    <span className={Style.nav_title}>{trans.address}</span>

                    <p className="text-white">
                        <Address /><br />
                        <Phone />
                    </p>
                </div>
                <div className={Style.tuev}>
                    <Img fluid={tuevLogo.childImageSharp.fluid} alt={"TÜV Süd Software Verified Escrow"} />
                </div>
            </div>

            <hr className={`mt-12 ${Style.hr}`} />

            <div className="flex flex-wrap desktop:flex-no-wrap mt-12 font-bold text-center desktop:text-left">
                <div className="w-full tablet:mb-4 desktop:w-1/3">&copy; {new Date().getFullYear()} Quadio</div>
                <div className="w-full desktop:w-1/3 text-center">Made with ❤ in Lüneburg</div>
            </div>
        </div>
    </footer>
}

export default Footer
