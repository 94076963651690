export const trans = {
    de: {
        i18n: {
            make_inquiry: "Termin anfordern",
            call_us: "Oder rufen Sie uns an:",
            submit: {
                normal: "Absenden",
                loading: "Senden ...",
            },
            sections: {
                information: {
                    title: "Informationen",
                    gender: {
                        label: "Anrede",
                        placeholder: "Auswählen",
                        options: [
                            {
                                id: "m",
                                title: "Herr"
                            },
                            {
                                id: "f",
                                title: "Frau"
                            },
                            {
                                id: "d",
                                title: "Keine"
                            }
                        ]
                    },
                    first_name: {
                        label: "Vorname",
                        placeholder: "Vorname",
                    },
                    last_name: {
                        label: "Nachname",
                        placeholder: "Nachname",
                    },
                    email: {
                        label: "E-Mail",
                        placeholder: "z.B. max@quadio.com",
                    },
                    company: {
                        label: "Unternehmen",
                        placeholder: "Unternehmen",
                    },
                    website_info: {
                        label: "Bitte lassen Sie dieses Feld leer.",
                        placeholder: "Bitte lassen Sie dieses Feld leer.",
                    },
                    callback: {
                        label: "Rufen Sie mich zurück",
                    },
                    phone: {
                        label: "Telefon",
                        placeholder: "Hier Ihre Telefonnummer",
                    },
                    call_direct: {
                        title: "Oder rufen Sie direkt an:",
                        call_now: "Jetzt anrufen",
                    },
                    further_agreement_required: {
                        label: "Betrachten Sie dieses Feld bitte als Gegenstandlos, und wählen Sie es nicht an."
                    },
                    validations: {
                        names_validation: "Bitte tragen Sie Ihren Namen und eine Anrede ein, damit wir Sie ansprechen können!",
                        company_validation: "Bitte tragen Sie Ihr Unternehmen ein, damit wir Sie ansprechen können!",
                        company_email_validation: "Bitte tragen Sie Ihre E-Mail Adresse sowie Ihr Unternehmen ein, damit wir Sie ansprechen können!",
                        phone: "Bitte tragen Sie Ihre Telefonnummer ein, damit wir Sie zurückrufen können.",
                    },
                },
                help: {
                    title: "Wie können wir helfen?",
                    product: {
                        label: "Produkt",
                        placeholder: "Auswählen",
                        options: [
                            {
                                id: "consult",
                                title: "Bitte beraten Sie mich"
                            },
                            {
                                id: "culture",
                                title: "Quadio Culture"
                            },
                            {
                                id: "sales",
                                title: "Quadio Sales"
                            },
                            {
                                id: "training",
                                title: "Quadio Training"
                            },
                        ],
                    },
                    message: {
                        label: "Mitteilung",
                        placeholder: "Wie können wir helfen?",
                    },
                }
            }
        }
    },
    en: {
        i18n: {
            make_inquiry: "Make an Inquiry",
            call_us: "Or Call Us:",
            submit: {
                normal: "Submit",
                loading: "Please wait ...",
            },
            sections: {
                information: {
                    title: "Personal Information",
                    gender: {
                        label: "Title",
                        placeholder: "Select",
                        options: [
                            {
                                id: "m",
                                title: "Mr."
                            },
                            {
                                id: "f",
                                title: "Mrs."
                            },
                            {
                                id: "d",
                                title: "None"
                            }
                        ]
                    },
                    first_name: {
                        label: "First Name",
                        placeholder: "First Name",
                    },
                    last_name: {
                        label: "Last Name",
                        placeholder: "Last Name",
                    },
                    email: {
                        label: "E-Mail",
                        placeholder: "e.g. max@quadio.com",
                    },
                    company: {
                        label: "Company",
                        placeholder: "Company",
                    },
                    website_info: {
                        label: "Please leave this field empty.",
                        placeholder: "Please leave this field empty.",
                    },
                    callback: {
                        label: "Call me back",
                    },
                    phone: {
                        label: "Phone",
                        placeholder: "Your phone number here",
                    },
                    call_direct: {
                        title: "Or Call Us:",
                        call_now: "Call now",
                    },
                    further_agreement_required: {
                        label: "Please do not select this field."
                    },
                    validations: {
                        names_validation: "Please enter your name",
                        company_validation: "Please enter your company",
                        company_email_validation: "Please enter your company and your email address",
                        phone: "Please enter your phone number",
                    },
                },
                help: {
                    title: "How can we help?",
                    product: {
                        label: "Product",
                        placeholder: "Select",
                        options: [
                            {
                                id: "consult",
                                title: "Please advice me"
                            },
                            {
                                id: "culture",
                                title: "Quadio Culture"
                            },
                            {
                                id: "sales",
                                title: "Quadio Sales"
                            },
                            {
                                id: "training",
                                title: "Quadio Training"
                            },
                        ],
                    },
                    message: {
                        label: "Message",
                        placeholder: "How can we help you?",
                    },
                }
            }
        }
    },
}
