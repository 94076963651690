import React, { ComponentProps, useMemo } from "react"
import { Link } from "gatsby"
import { useLanguageContext } from "../helpers/LanguageContext"

const LangAwareLink: React.FC<ComponentProps<typeof Link>> = ({ ref, to, ...props }) => {
    const { homeLink } = useLanguageContext()

    const dest = useMemo(() => {
        return `${homeLink}${to}`.replace("//", "/")
    }, [to, homeLink])

    return <Link {...props} to={dest} />
}

export default LangAwareLink
