import React, { useCallback, useContext, useState } from "react"
import { ga_event } from "../../handle_ga"

interface DateOverlayContextType {
    visible: boolean
    sent: boolean,
    scrollY: number,

    showOverlay: () => void
    hideOverlay: () => void
    setSent: () => void,
    setScrollY: (y: number) => void
}
const DateOverlayContext = React.createContext<DateOverlayContextType | undefined>(undefined)

export const useDateOverlayContext = () => {
    const ctx = useContext(DateOverlayContext)

    if (!ctx) {
        throw new Error("No DateOverlayProvider available.")
    }

    return ctx
}

export const DateOverlayProvider: React.FC = ({ children }) => {
    const [visible, setVisible] = useState(false)
    const [sent, setSentState] = useState(false)
    const [scrollY, setScrollY] = useState(0)

    const showOverlay = useCallback(() => {
        setVisible(true)
        setScrollY(window.scrollY)

        ga_event("screen_view", {
            screen_name: "appointment-form"
        })
    }, [])

    const hideOverlay = useCallback(() => {
        setVisible(false)
        setSentState(false)

        if (!sent) {
            ga_event("formular_close", {
                event_category: "engagement",
                event_label: "Appointment Form closed without sending"
            })
        }
    }, [sent])

    const setSent = useCallback(() => {
        setSentState(true)
        ga_event("formular_done", {
            event_category: "engagement",
            event_label: "Appointment Form sent"
        })
    }, [])

    const val: DateOverlayContextType = {
        visible,
        sent,
        scrollY,

        showOverlay,
        hideOverlay,
        setSent,
        setScrollY,
    }

    return <DateOverlayContext.Provider value={val}>
        {children}
    </DateOverlayContext.Provider>
}
