import React, { ButtonHTMLAttributes, ComponentProps, HTMLProps, useMemo } from "react"

import Style from "./Button.module.css"
import classNames from "../../helpers/classNames"
import LangAwareLink from "../LangAwareLink"

interface AdditionalButtonProps {
    appearance: "primary" | "secondary" | "light_blue" | "other_light_blue" | "blue_middle"
    className?: string
    equalPadding?: boolean
}

type ButtonProps<T> = T & AdditionalButtonProps

function usePrepareProps<T>({ appearance, className = "", equalPadding = false, ...props}: ButtonProps<T>) {
    return useMemo(() => {
        return {
            ...props,
            className: classNames({
                [Style.default]: true,
                [Style[appearance]]: true,
                [className]: true,
                [Style.equalPadding]: equalPadding,
            })
        }
    }, [props, appearance, className, equalPadding])
}

export const Button: React.FC<ButtonProps<ButtonHTMLAttributes<HTMLButtonElement>>> = (props) => {
    const applyProps = usePrepareProps(props)

    return <button {...applyProps} />
}

export const ButtonLink: React.FC<ButtonProps<ComponentProps<typeof LangAwareLink>>> = ({ ref, ...props }) => {
    const applyProps = usePrepareProps(props)

    return <LangAwareLink {...applyProps} />
}

export const ButtonA: React.FC<ButtonProps<HTMLProps<HTMLAnchorElement>>> = ({ children, ...props }) => {
    const applyProps = usePrepareProps(props)

    return <a {...applyProps}>
        {children}
    </a>
}
