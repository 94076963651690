import React, { createContext, useContext, useMemo } from "react"
import { RouteComponentProps } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"

type GatsbyLocation = RouteComponentProps["location"]

type LanguageContextType = {
    location: GatsbyLocation
    homeLink: string
    langMenu: {
        langKey: "de" | "en"
        selected: boolean
        link: string
    }[]
    currentLangKey: "de" | "en"
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined)

export const useLanguageContext = () => {
    const context = useContext(LanguageContext)

    if (!context) {
        throw new Error("No LanguageContextProvider found.")
    }

    return context
}

export const LanguageContextProvider: React.FC<{
    location: GatsbyLocation
}> = ({ location, children }) => {
    const {
        site: {
            siteMetadata: {
                languages: {
                    langs,
                    defaultLangKey,
                }
            }
        }
    } = useStaticQuery(graphql`        
        query LanguageCtxData {
            site {
                siteMetadata {
                    languages {
                        defaultLangKey
                        langs
                    }
                }
            }
        }
    `)

    const contextValues = useMemo<LanguageContextType>(() => {
        if (!langs) {
            return {
                location,
                homeLink: "/",
                langMenu: [],
                currentLangKey: "de",
            }
        }

        const { pathname: url = "/" } = location || {}

        const currentLangKey = getCurrentLangKey(langs, defaultLangKey, url) as any

        const homeLink = `/${currentLangKey}`
            .replace(`/${defaultLangKey}`, '/')

        const langMenu = getLangs(
            langs,
            currentLangKey,
            getUrlForLang(homeLink, url)
        ).map((item) => ({
            ...item,
            link: item.link
                .replace("//", "/")
                .replace(`/${defaultLangKey}/`, '/')
        })) as {
            langKey: "de" | "en"
            selected: boolean
            link: string
        }[]

        return {
            location,
            homeLink,
            langMenu,
            currentLangKey,
        }
    }, [location, langs, defaultLangKey])

    return <LanguageContext.Provider value={contextValues}>
        {children}
    </LanguageContext.Provider>
}
