/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet-async"

import { useStaticQuery, graphql } from "gatsby"
import { useLanguageContext } from "../helpers/LanguageContext"

interface SeoProps {
    description?: string
    keywords?: string
    meta?: any[]
    title: string
}
const Seo: React.FC<SeoProps> = ({
    description = "",
    meta = [],
    title,
    keywords = "",
}) => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `
    )

    const { currentLangKey, langMenu, location } = useLanguageContext()

    const metaDescription = description || site.siteMetadata.description
    const url = `${location?.protocol}//${location?.host}`

    return (
        <Helmet
            htmlAttributes={{
                lang: currentLangKey,
            }}
            title={title}
            titleTemplate={`%s`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: "keywords",
                    content: keywords,
                }
            ].concat(meta)}
        >
            {langMenu.map(({ langKey, link }) => {
                return <link
                    rel="alternate"
                    hrefLang={langKey}
                    href={`${url}${link}`}
                    key={`head-alternative-${langKey}`}
                />
            })}
        </Helmet>
    )
}

export default Seo
