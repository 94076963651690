import React from "react"

import QuadioLogo from "../../images/logo.inline.svg"
import { Button } from "../Button/Button"
import { Phone } from "../Address";

const trans = {
    de: {
        title: "Erfolg!",
        message: "Ihre Nachricht hat uns erreicht und wir freuen uns bald mit Ihnen in Verbindung zu treten.",
        close: "Schließen",
        cantWait: "Sie können es nicht erwarten?"
    },
    en: {
        title: "Success!",
        message: "Your message has reached us, we will contact you as soon as possible.",
        close: "Close",
        cantWait: "If you want to start right away, let us know:"
    },
}

export const DateOverlayDone: React.FC<{
    lang: "de" | "en"
    hideOverlay: () => void
    className?: string
}> = ({ lang, hideOverlay, className }) => {
    const i18n = trans[lang]

    return <div className={className}>
        <div className="m-auto mb-32 px-4">
            <QuadioLogo className="m-auto" />
            <h1 className="mt-8 mb-4">{i18n.title}</h1>
            <p>
                {i18n.message}
            </p>
            <Button appearance="primary" type="button" onClick={hideOverlay}>
                {i18n.close}
            </Button>
        </div>
        <div className="mt-auto mb-0">
            <span className="block font-bold mb-2">{i18n.cantWait}</span>
            <Phone />
        </div>
    </div>
}
