import React, { useEffect, useRef } from "react"
import { useDateOverlayContext } from "./DateOverlayContext"

import QuadioLogo from "../../images/logo.inline.svg"
import CloseIcon from "../../images/icons/close.inline.svg"

import DateOverlayForm from "./DateOverlayForm"
import classNames from "../../helpers/classNames"

import { useLanguageContext } from "../../helpers/LanguageContext"
import { DateOverlayDone } from "./DateOverlayDone"

import Style from "./DateOverlay.module.css"

export const DateOverlay: React.FC = ({ children }) => {
    const { visible, sent, hideOverlay, scrollY, setScrollY } = useDateOverlayContext()
    const { currentLangKey } = useLanguageContext()

    const overlayRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!visible) {
            if (scrollY) {
                window.scrollTo(0, scrollY)
                setScrollY(0)
            }
            return
        }

        overlayRef.current && overlayRef.current.focus();

        window.scrollTo(0, 0)
    }, [visible, scrollY, setScrollY]);

    return <>
        {visible && <div ref={overlayRef} className={classNames({
            [Style.overlay]: true,
            [Style.overlay_sent]: sent
        })}>
            <header className={Style.header}>
                {!sent && <div className="m-auto ml-0">
                    <QuadioLogo />
                </div>}
                <div className="m-auto mr-0">
                    <button onClick={hideOverlay}><CloseIcon /></button>
                </div>
            </header>
            {!sent && <DateOverlayForm lang={currentLangKey} />}
            {sent && <DateOverlayDone
                className={Style.sentWrapper}
                lang={currentLangKey}

                hideOverlay={hideOverlay}
            />}
        </div>}
        <div tabIndex={-1} role="group" hidden={visible} aria-hidden={visible}>
            {children}
        </div>
    </>
}
