import React, { ChangeEventHandler, useCallback, useState } from "react"
import { Check, Form, Input, Select } from "@rocketseat/unform"

import * as Yup from "yup"

import { Button, ButtonA } from "../Button/Button"

import { ValidationError } from "yup"
import { useDateOverlayContext } from "./DateOverlayContext"
import PhoneIcon from "../../images/icons/phone.inline.svg"

import { ga_event } from "../../handle_ga"
import { trans } from "./DateOverlayForm.i18n"

import Style from "./DateOverlayForm.module.css"
import { Phone } from "../Address";

const genders = [
    {
        id: "m",
        title: "Herr"
    },
    {
        id: "f",
        title: "Frau"
    },
    {
        id: "d",
        title: "Keine"
    }
]

const products = [
    {
        id: "consult",
        title: "Bitte beraten Sie mich"
    },
    {
        id: "culture",
        title: "Quadio Culture"
    },
    {
        id: "sales",
        title: "Quadio Sales"
    },
    {
        id: "training",
        title: "Quadio Training"
    },
]

const validation = Yup.object().shape({
    gender: Yup.string().oneOf(genders.map(({ id }) => id)),
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    email: Yup.string().when(['callback'], {
        is: true,
        then: Yup.string().email().notRequired(),
        otherwise: Yup.string().email().required(),
    }),
    company: Yup.string().required(),
    phone: Yup.string().when(['callback'], {
        is: true,
        then: Yup.string().required(),
        otherwise: Yup.string().notRequired(),
    }),
    product: Yup.string().oneOf(products.map(({ id }) => id)),
    further_agreement_required: Yup.boolean().notRequired(),
    website_info: Yup.string().notRequired(),
})

type DataType = Yup.InferType<typeof validation>

const endpoint = "https://api.quadio.de/form-submissions/submissions/submit/597dde41-0edd-4210-b092-ee57c407c7ac"

const DateOverlayForm: React.FC<{
    lang: "de" | "en"
}> = ({ lang }) => {
    const [callBack, setCallBack] = useState(false)
    const [errors, setErrors] = useState<{
        [key in keyof DataType]?: ValidationError
    }>({})
    const [submitting, setSubmitting] = useState(false)

    const { sent, setSent } = useDateOverlayContext()

    const changeCallBack = useCallback<ChangeEventHandler<HTMLInputElement>>(({ target: { checked } }) => {
        setCallBack(checked)
    }, [setCallBack])

    const onSubmit = useCallback(async (data: any) => {
        if (submitting || sent) {
            return
        }

        setSubmitting(true)

        let validated
        try {
            validated = await validation.validate(data, {
                abortEarly: false,
            })
        } catch (err) {
            if (!(err instanceof ValidationError)) {
                return
            }

            setErrors(Object.fromEntries(err.inner.map((inner) => {
                return [inner.path, inner]
            })))

            return
        }

        const formdata = new FormData();

        Object.entries(validated as DataType).forEach(([key, val]) => {
            if (!val) {
                return
            }

            if (val === true) {
                formdata.append(key, "on")
                return
            }

            formdata.append(key, val)
        })

        try {
            await fetch(endpoint, {
                method: "post",
                headers: {
                    "Accept": "application/json"
                },
                body: formdata
            })
        } catch (ignore) {
            setSubmitting(false)
            ga_event("exception", {
                "description": "form was not sent"
            })
            return
        }

        setSent()
        setSubmitting(false)
    }, [submitting, sent, setSent])

    const { i18n } = trans[lang]

    return <Form
        onSubmit={onSubmit}
        action={endpoint}
    >
        <div className={Style.outer}>
            <div className="flex tablet:flex-wrap items-center my-8 desktop:my-16">
                <h1 className="my-auto tablet:w-full">{i18n.make_inquiry}</h1>
                <div className="my-auto ml-0 desktop:ml-auto desktop:mr-0 tablet:mt-4">
                    <span className="desktop:block font-bold tablet:mr-2">{i18n.call_us}</span>
                    <Phone />
                </div>
            </div>
            <h2 className={Style.headline}>{i18n.sections.information.title}</h2>
            <div className={Style.inputGroup}>
                <div className={Style.inputRow}>
                    <div className={`${Style.inputWrapper} w-1/3`}>
                        <Select required name="gender" label={i18n.sections.information.gender.label} placeholder={i18n.sections.information.gender.placeholder} options={i18n.sections.information.gender.options} />
                    </div>
                    <div className={`${Style.inputWrapper} w-1/3`}>
                        <Input required name="first_name" label={i18n.sections.information.first_name.label} placeholder={i18n.sections.information.first_name.placeholder} />
                    </div>
                    <div className={`${Style.inputWrapper} w-1/3`}>
                        <Input required name="last_name" label={i18n.sections.information.last_name.label} placeholder={i18n.sections.information.last_name.placeholder} />
                    </div>
                </div>
                {
                    (errors.gender || errors.first_name || errors.last_name) &&
                    <div className={`${Style.validation} w-1/3`}>
                        {i18n.sections.information.validations.names_validation}
                    </div>
                }
            </div>
            <div className={Style.inputGroup}>
                <div className={Style.inputRow}>
                    <div className={`${Style.inputWrapper} w-1/2`}>
                        <Input required name="email" label={i18n.sections.information.email.label} placeholder={i18n.sections.information.email.placeholder} />
                    </div>
                    <div className={`${Style.inputWrapper} w-1/2`}>
                        <Input required name="company" label={i18n.sections.information.company.label} placeholder={i18n.sections.information.company.placeholder} />
                    </div>
                    <div className={Style.specialInput}>
                        <Input name="website_info" label={i18n.sections.information.website_info.label} placeholder={i18n.sections.information.website_info.placeholder} />
                    </div>
                </div>
                {
                    (callBack && errors.company) &&
                    <div className={`${ Style.validation } w-1/3`}>
                        {i18n.sections.information.validations.company_validation}
                    </div>
                }
                {
                    !callBack && (errors.email || errors.company) &&
                    <div className={`${ Style.validation } w-1/3`}>
                        {i18n.sections.information.validations.company_email_validation}
                    </div>
                }
            </div>
            <div className={Style.inputGroup}>
                <div className={Style.inputRow}>
                    <div className={`${Style.inputWrapper} w-full mt-6`}>
                        <Check value="on" onChange={changeCallBack} name="callback" label={i18n.sections.information.callback.label} />
                    </div>
                </div>
            </div>
            <div hidden={!callBack} aria-hidden={!callBack}>
                <div className={Style.inputGroup}>
                    <div className={Style.inputRow}>
                        <div className={`${Style.inputWrapper} w-full`}>
                            <Input name="phone" type="tel" label={i18n.sections.information.phone.label} placeholder={i18n.sections.information.phone.placeholder} />
                        </div>
                        <div hidden className={`mobile:block w-full mt-6`}>
                            <span className="block font-bold mb-4">{i18n.sections.information.call_direct.title}</span>
                            <ButtonA
                                appearance="light_blue"
                                className="w-full text-center"
                                href="tel:+494046000870"
                            >
                                <span className="flex items-center justify-center">
                                    <PhoneIcon />
                                    <span className="ml-2">{i18n.sections.information.call_direct.call_now}</span>
                                </span>
                            </ButtonA>
                        </div>
                    </div>
                    {
                        errors.phone &&
                        <div className={`${Style.validation} w-1/3`}>
                            {i18n.sections.information.validations.phone}
                        </div>
                    }
                </div>
            </div>
            <div className={Style.inputGroup}>
                <div className={Style.specialInput}>
                    <Check value="on" name="further_agreement_required" label={i18n.sections.information.further_agreement_required.label} />
                </div>
            </div>

            <hr className={Style.hr} />

            <h2 className={Style.headline}>{i18n.sections.help.title}</h2>
            <div className={Style.inputGroup}>
                <div className={Style.inputRow}>
                    <div className={`${Style.inputWrapper} w-full`}>
                        <Select name="product" label={i18n.sections.help.product.label} placeholder={i18n.sections.help.product.placeholder} options={i18n.sections.help.product.options} />
                    </div>
                </div>
            </div>
            <div className={Style.inputGroup}>
                <div className={Style.inputRow}>
                    <div className={`${Style.inputWrapper} w-full`}>
                        <Input multiline name="message" label={i18n.sections.help.message.label} placeholder={i18n.sections.help.message.placeholder} />
                    </div>
                </div>
            </div>

            <hr className={Style.hr} />

            <div className="flex items-center">
                <div className="my-auto ml-0 mr-auto font-bold">
                    Made with ❤ in Lüneburg
                </div>
                <div className="my-auto ml-auto mr-0">
                    <Button appearance="primary" type="submit">
                        {submitting && i18n.submit.loading}
                        {!submitting && i18n.submit.normal}
                    </Button>
                </div>
            </div>
        </div>
    </Form>
}

export default DateOverlayForm
