import React from "react"
import { useLanguageContext } from "../helpers/LanguageContext";

export const Address: React.FC = () => {
    const { currentLangKey } = useLanguageContext()

	return <>
		Quadio 2 Business GmbH<br />
        Witwenkamp 17<br />
        21357 Bardowick
        {currentLangKey !== "de" && <>
            <br />Germany
        </>}
	</>
}

export const Phone: React.FC = () => {
    return <a href="tel:+4941319279173">+49 4131 / 9279-173</a>
}
