/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import "../styles/tailwind.css"
import "../styles/global.css"
import Style from "./Layout.module.css"

import Header from "./Header"
import Footer from "./Footer"

import { DateOverlay } from "./DateOverlay/DateOverlay"
import { DateOverlayProvider } from "./DateOverlay/DateOverlayContext"
import { RouteComponentProps } from "@reach/router"
import { LanguageContextProvider } from "../helpers/LanguageContext"

const Layout: React.FC<{
    location: RouteComponentProps["location"]
}> = ({ location, children }) => {
    return <LanguageContextProvider location={location}>
        <DateOverlayProvider>
            <DateOverlay>
                <div className={Style.wrapper}>
                    <Header />
                    <div className="my-auto">
                        <main>{children}</main>
                    </div>
                    <Footer />
                </div>
            </DateOverlay>
        </DateOverlayProvider>
    </LanguageContextProvider>
}

export default Layout
