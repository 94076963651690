import React, { ComponentProps, useCallback, useMemo, useState } from "react"

import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { useDateOverlayContext } from "./DateOverlay/DateOverlayContext"

import QuadioLogo from "../images/logo.inline.svg"
import GlobeIcon from "../images/icons/globe.inline.svg"
import CloseIcon from "../images/icons/close.inline.svg"
import BurgerIcon from "../images/icons/burger.inline.svg"

import classNames from "../helpers/classNames"
import { Button } from "./Button/Button"
import { useLanguageContext } from "../helpers/LanguageContext"
import LangAwareLink from "./LangAwareLink"

import Style from "./Header.module.css"

const navLangs = {
    de: {
        start: "Übersicht",
        product_header: "Produkte",
        products: {
            culture: {
                title: "Quadio Culture",
                short: "Culture",
                text: "Onboarden, ausrichten, durchstarten. Viele Persönlichkeiten und eine Vision. Das gelingt mit guter Führung, einer starken Unternehmenskultur und Quadio.",
            },
            training: {
                title: "Quadio Training",
                short: "Training",
                text: "Ihren Return On Learning Invest steigern mit effektivem Wissensmanagement und dem Verfestigen von Wissen. Jederzeit und überall.",
            },
            sales: {
                title: "Quadio Sales",
                short: "Sales",
                text: "Aus Vertrieblern Markenbotschafter machen, die erklärungsbedürftige Produkte auf den Punkt bringen und aus Kunden Fans machen, die besser wissen und erleben.",
            },
        },
        aboutUs: "Über Uns",
        date: "Termin anfordern",
        learnMore: "Mehr erfahren",
        close: "Schließen",

        langs: {
            de: "Deutsch (DE)",
            en: "Englisch (EN)"
        }
    },
    en: {
        start: "Overview",
        product_header: "Products",
        products: {
            culture: {
                title: "Quadio Culture",
                short: "Culture",
                text: "Get onboard, get set, kickoff. Various personalities and one vision. Excellent leadership and strong corporate culture coupled with Quadio is the best way to yield success.",
            },
            training: {
                title: "Quadio Training",
                short: "Training",
                text: "Maximize the ‘Return on Learning Invest’ with effective knowledge management and reinforced knowledge acquisition regardless of time and place.",
            },
            sales: {
                title: "Quadio Sales",
                short: "Sales",
                text: "Sales representatives grown into brand ambassadors help define an optimal brand portfolio strategy with maximum reach and minimum explanatory need whilst customers converted into followers excel in understanding the products and thus create a unique user experience.",
            },
        },
        aboutUs: "About us",
        date: "Make an Inquiry",
        learnMore: "Mehr erfahren",
        close: "Close",

        langs: {
            de: "German (DE)",
            en: "English (EN)"
        }
    }
}

const Header: React.FC = () => {
    const { showOverlay } = useDateOverlayContext()
    const [show, setShow] = useState(false)
    const { homeLink, currentLangKey, langMenu } = useLanguageContext()

    const {
        cultureImage,
        trainingImage,
        salesImage,
    } = useStaticQuery(graphql`
        fragment HeaderImage on File {
            childImageSharp {
                fixed(width: 192, height: 128, quality: 90) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }

        query HeaderImages {
            cultureImage: file (relativePath: { eq: "culture.jpg" }) {
                ...HeaderImage
            }
            trainingImage: file (relativePath: { eq: "training.jpg" }) {
                ...HeaderImage
            }
            salesImage: file (relativePath: { eq: "sales.jpg" }) {
                ...HeaderImage
            }
        }
    `)

    const showNav = useCallback(() => {
        setShow(true)
    }, [setShow])

    const hideNav = useCallback(() => {
        setShow(false)
    }, [setShow])

    const Link = useCallback<React.FC<ComponentProps<typeof GatsbyLink>>>(({ ref, ...props }) => {
        return <LangAwareLink onClick={hideNav} {...props} />
    }, [hideNav])

    const navStrings = useMemo(() => {
        return navLangs[currentLangKey]
    }, [currentLangKey])

    return <header className={classNames({
        [Style.header]: true,
        [Style.header_active]: show
    })}>
        {/* TODO: active states */}
        <div className={Style.nav_contact_wrap}>
            <GatsbyLink aria-hidden={true} to={homeLink} className={Style.brand}>
                <QuadioLogo role="img" />
            </GatsbyLink>

            <nav className={Style.nav} role="navigation">
                <header hidden aria-hidden className={Style.nav_mobile_header}>
                    <GatsbyLink to={homeLink} className={Style.brand}>
                        Quadio
                    </GatsbyLink>

                    <button hidden aria-hidden onClick={showOverlay} className={`${Style.cta} ${Style.mobile_cta}`}>{navStrings.date}</button>
                </header>
                <ul className={Style.nav_list}>
                    <li className={Style.nav_link_wrapper}>
                        <GatsbyLink to={homeLink} className={Style.nav_link}>{navStrings.start}</GatsbyLink>
                    </li>
                    <li className={`${Style.nav_link_wrapper} ${Style.nav_link_wrapper_children}`}>
                        <span className={Style.nav_link}>{navStrings.product_header}</span>
                        <ul className={`${Style.nav_dropdown} ${Style.products_dropdown}`}>
                            <li className={Style.nav_product}>
                                <div hidden aria-hidden={true} className={`${Style.product_cta_wrapper}`}>
                                    <div className={Style.product_cta_background}>
                                        <Img className={Style.product_cta_background_image} fixed={cultureImage.childImageSharp.fixed} />
                                    </div>
                                    <span className={Style.product_cta}>{navStrings.learnMore}</span>
                                </div>
                                <div className={Style.product_info}>
                                    <h3>{navStrings.products.culture.title}</h3>
                                    <p>{navStrings.products.culture.text}</p>
                                </div>
                                <Link className={Style.stretchedLink} to="/products/culture">
                                    <span className={Style.desktopProductLink}>{navStrings.learnMore}</span>
                                    <span hidden aria-hidden className={Style.mobileProductLink}>{navStrings.products.culture.short}</span>
                                </Link>
                            </li>
                            <li className={Style.nav_product}>
                                <div hidden aria-hidden={true} className={`${Style.product_cta_wrapper}`}>
                                    <div className={Style.product_cta_background}>
                                        <Img className={Style.product_cta_background_image} fixed={trainingImage.childImageSharp.fixed} />
                                    </div>
                                    <span className={Style.product_cta}>{navStrings.learnMore}</span>
                                </div>
                                <div className={Style.product_info}>
                                    <h3>{navStrings.products.training.title}</h3>
                                    <p>{navStrings.products.training.text}</p>
                                </div>
                                <Link className={Style.stretchedLink} to="/products/training">
                                    <span className={Style.desktopProductLink}>{navStrings.learnMore}</span>
                                    <span hidden aria-hidden className={Style.mobileProductLink}>{navStrings.products.training.short}</span>
                                </Link>
                            </li>
                            <li className={Style.nav_product}>
                                <div hidden aria-hidden={true} className={`${Style.product_cta_wrapper}`}>
                                    <div className={Style.product_cta_background}>
                                        <Img className={Style.product_cta_background_image} fixed={salesImage.childImageSharp.fixed} />
                                    </div>
                                    <span className={Style.product_cta}>{navStrings.learnMore}</span>
                                </div>
                                <div className={Style.product_info}>
                                    <h3>{navStrings.products.sales.title}</h3>
                                    <p>{navStrings.products.sales.text}</p>
                                </div>
                                <Link className={Style.stretchedLink} to="/products/sales">
                                    <span className={Style.desktopProductLink}>{navStrings.learnMore}</span>
                                    <span hidden aria-hidden className={Style.mobileProductLink}>{navStrings.products.sales.short}</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className={Style.nav_link_wrapper}><Link to="/about-us" className={Style.nav_link}>{navStrings.aboutUs}</Link></li>
                    <li className={`${Style.nav_link_wrapper} ${Style.mobile_hidden}`}>
                        <span className={`${Style.nav_link} uppercase`}>{currentLangKey}</span>
                        <ul className={Style.nav_dropdown}>
                            {langMenu.map(({ langKey, link, selected }) => {
                                return <li key={`header-nav-${langKey}`} className={Style.nav_link_wrapper}>
                                    <GatsbyLink to={link} className={`${Style.nav_link} ${Style.nav_lang_link} uppercase`} data-selected={selected}>{navStrings.langs[langKey]}</GatsbyLink>
                                </li>
                            })}
                        </ul>
                    </li>
                    <li
                        hidden
                        aria-hidden
                        className={`${Style.nav_link_wrapper} ${Style.only_mobile}`}
                    >
                        <a href="https://app.quadio.de" className={Style.nav_link}>Login</a>
                    </li>
                </ul>
                <footer hidden aria-hidden className={Style.nav_mobile_footer}>
                    {
                        langMenu
                            // TODO: this will only work for two languages.
                            .filter(({ langKey }) => langKey !== currentLangKey)
                            .map(({langKey, link}) => {
                                return <GatsbyLink
                                    key={`mobile-header-lang-nav-${langKey}`}
                                    to={link}
                                    className={Style.mobile_lang}
                                >
                                    <GlobeIcon className="force-override-stroke" aria-hidden />
                                    <span className="ml-2 uppercase">{langKey}</span>
                                </GatsbyLink>
                            })
                    }
                    <button onClick={hideNav} className={Style.close}>
                        <CloseIcon className="force-override-stroke" aria-hidden />
                        <span className="ml-2">{navStrings.close}</span>
                    </button>
                </footer>
            </nav>

            <div className={Style.cta_wrap}>
                <a href="https://app.quadio.de" className={Style.login_link}>Login</a>
                <Button appearance="other_light_blue" onClick={showOverlay} className={Style.cta}>{navStrings.date}</Button>
            </div>
        </div>
        <button onClick={showNav} hidden aria-hidden className={Style.open}>
            <BurgerIcon className="force-override-stroke" aria-hidden />
            <span className="ml-2">Navigation</span>
        </button>
    </header>
}

export default Header
